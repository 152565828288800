import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "'' The pandemic took a toll on my body with excess bodyfat and staying indoors. I made the right choice by choosing the Dhruv's fitness portal and by choosing the right plan and program I have achieved my ideal body! ''",
    name: 'Rohan Jain',
    status : 'Software Engineer'
  },
  {
    image: image2,
    review: "'' Undoubtedly one of the best and easiest to use fitness portal perfect for bodyweight exercises and high intensity cardio. ''",
    name: 'Joseph Matthew.',
    status: 'Fitness Trainer'
  },
  {
    image : image3,
    review:"'' Thanks to Dhruvs portal I have found my lost confidence and reach my ideal bmi and feel lighter than before. ''",
    name: 'Mr Muralidharan',
    status: "CUSTOMER"
  }
];
